import qs from "query-string"

import { addTrailingSlash, removeFrontSlash } from "./strings"

export function isValidUrl(str: string) {
  try {
    new URL(str)
  } catch {
    return false
  }

  return true
}

export function isValidHttpUrl(str: string) {
  let url
  try {
    url = new URL(str)
  } catch {
    return false
  }

  return url.protocol === "http:" || url.protocol === "https:"
}

export function resolveUrl(base: string, path: string) {
  if (!isValidUrl(base)) {
    return path
  }

  // removing front slash to ensure the whole url is concatenated
  //   because URL implementation behaves differently
  //   https://developer.mozilla.org/en-US/docs/Web/API/URL/URL
  return new URL(removeFrontSlash(path), addTrailingSlash(base)).toString()
}

export function addSearchToUrl(url: string, search: qs.StringifiableRecord, mode: "append" | "replace" = "append") {
  if (mode === "replace") {
    return qs.stringifyUrl({ url, query: search })
  }

  const parsed = qs.parseUrl(url)
  return qs.stringifyUrl({ url, query: { ...parsed.query, ...search } })
}

export function extractHostnameFromHost(host: string) {
  return new URL(`http://${host}`).hostname
}

export function addSubdomainToUrl(url: string, subdomain: string): string {
  const parsedUrl = new URL(url)
  parsedUrl.hostname = `${subdomain}.${parsedUrl.hostname}`
  return parsedUrl.toString()
}
