var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"not-set"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/assets/proteus";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

import env from "./src/utils/env.mjs"

const SENTRY_DSN = env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  release: env.NEXT_PUBLIC_SENTRY_RELEASE,
  environment: env.NEXT_PUBLIC_ENVIRONMENT,
  enabled: !env.NEXT_PUBLIC_SENTRY_DISABLE,
  beforeBreadcrumb: (breadcrumb) => {
    if (breadcrumb.type === "http" && breadcrumb.data?.url && breadcrumb.data?.url.length > 100) {
      breadcrumb.data.url = breadcrumb.data?.url.slice(0, 100) + "<ZKRACENO>"
    }

    return breadcrumb
  },
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [Sentry.replayIntegration({ maskAllInputs: false, maskAllText: false, blockAllMedia: false })],
})

Sentry.setTag("component", "proteus")
