import { assertUnreachable } from "./typeguards"

type AzimuthFormat = "-180/180" | "0/360"

type ConvertAzimuthFormatOptions = {
  from: AzimuthFormat
  to: AzimuthFormat
}

// TODO: based on format, clamp degrees value?
export function convertAzimuthFormat(degrees: number, opts: ConvertAzimuthFormatOptions): number {
  const { from, to } = opts
  switch (from) {
    case "-180/180": {
      switch (to) {
        case "-180/180":
          return degrees
        case "0/360":
          return degrees + 180
        default:
          return assertUnreachable(to)
      }
    }
    case "0/360": {
      switch (to) {
        case "-180/180":
          return degrees - 180
        case "0/360":
          return degrees
        default:
          return assertUnreachable(to)
      }
    }
    default:
      return assertUnreachable(from)
  }
}
