import type { Primitive } from "type-fest"

import { invariant, isNotNil } from "./typeguards"
import { Ensure } from "./types"

export function ensureKeys<TObject, TKey extends keyof TObject>(
  input: TObject,
  keys: TKey | TKey[],
): Ensure<TObject, TKey> {
  for (const key of Array.isArray(keys) ? keys : [keys]) {
    invariant(isNotNil(input[key]), `${key.toString()} is nil.`)
  }

  return { ...input } as Ensure<TObject, TKey>
}

export function copyKeysToValues<TKey extends string | number | symbol>(
  record: Record<TKey, unknown>,
): Record<TKey, TKey> {
  return Object.fromEntries(Object.keys(record).map((key) => [key, key])) as Record<TKey, TKey>
}

export function objectKeys<TObject extends object>(obj: TObject) {
  return Object.keys(obj) as (keyof TObject)[]
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

export function objectEntries<T extends object>(object: T): Entries<T> {
  return Object.entries(object) as Entries<T>
}

export type NullToUndefined<T> = T extends null
  ? undefined
  : // eslint-disable-next-line @typescript-eslint/ban-types
    T extends Primitive | Function | Date | RegExp
    ? T
    : T extends (infer U)[]
      ? NullToUndefined<U>[]
      : T extends Map<infer K, infer V>
        ? Map<K, NullToUndefined<V>>
        : T extends Set<infer U>
          ? Set<NullToUndefined<U>>
          : T extends object
            ? { [K in keyof T]: NullToUndefined<T[K]> }
            : unknown

function _nullToUndefined<T>(obj: T): NullToUndefined<T> {
  if (obj === null) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return undefined as any
  }

  if (typeof obj === "object") {
    if (obj instanceof Map) {
      obj.forEach((value, key) => obj.set(key, _nullToUndefined(value)))
    } else {
      for (const key in obj) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        obj[key] = _nullToUndefined(obj[key]) as any
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return obj as any
}

/**
 * Recursively converts all null values to undefined.
 *
 * @param obj object to convert
 * @returns a copy of the object with all its null values converted to undefined
 */
export function nullToUndefined<T>(obj: T) {
  return _nullToUndefined(structuredClone(obj))
}

type GetReturnType<T> = T | undefined
type ValueType = Record<string | number, unknown>

export function getProperty<T>(
  value: unknown,
  query: string | Array<string | number>,
  defaultVal: GetReturnType<T> = undefined,
): GetReturnType<T> {
  const splitQuery = Array.isArray(query)
    ? query
    : query
        .replace(/(\[(\d)])/g, ".$2")
        .replace(/^\./, "")
        .split(".")

  if (splitQuery.length === 0 || splitQuery[0] === undefined) {
    return value as T
  }

  const key = splitQuery[0]
  if (typeof value !== "object" || value === null || !(key in value) || (value as ValueType)[key] === undefined) {
    return defaultVal
  }

  return getProperty((value as ValueType)[key], splitQuery.slice(1), defaultVal)
}
