import { randomInt } from "./number"
import { isNil, isNotNil } from "./typeguards"

export function sleep(time: number) {
  return new Promise((done) => setTimeout(done, time))
}

type Opts = {
  name: string
  delayMs?: number | { min?: number; max: number }
  delayMode?: "with-processing" | "without-processing"
  deferStartByMs?: number
  onSuccess?: () => void | Promise<void>
  onError?: (error: unknown) => void
  processFn: (name: string, delay: number) => void | Promise<void>
}

function calculateDelayMs(opts: Opts): number {
  if (isNil(opts.delayMs)) {
    return 5000
  }

  if (typeof opts.delayMs === "number") {
    return opts.delayMs
  }

  return randomInt(opts.delayMs?.min ?? 0, opts.delayMs.max)
}

export function createAsyncLoop(opts: Opts) {
  const { delayMode = "with-processing", onError, onSuccess, name, processFn, deferStartByMs } = opts
  const delayMs = calculateDelayMs(opts)

  let isRunning = false
  let processFnPromise: ReturnType<typeof processFn> = undefined

  async function run() {
    if (isNotNil(deferStartByMs)) {
      await sleep(deferStartByMs)
    }

    do {
      const start = performance.now()
      try {
        processFnPromise = processFn(name, delayMs)
        await processFnPromise

        await onSuccess?.()
      } catch (error) {
        onError?.(error)
      }

      const processDuration = performance.now() - start
      const sleepTimeMs = delayMode === "with-processing" ? Math.max(delayMs - processDuration, 0) : delayMs
      await sleep(sleepTimeMs)
    } while (isRunning)
  }

  function start() {
    isRunning = true
    run()
  }

  function stop() {
    isRunning = false
  }

  async function stopAsync() {
    isRunning = false

    try {
      await processFnPromise
    } catch (error) {
      onError?.(error)
    }
  }

  return { start, stop, stopAsync } as const
}
