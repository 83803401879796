import { z } from "zod"

import { isNil } from "./typeguards"

type ErrMessage =
  | string
  | {
      message?: string
    }

// NOTE: https://github.com/colinhacks/zod/issues/310#issuecomment-794533682
export const emptyStringToUndefinedSchema = z.literal("").transform(() => undefined)

type IpSchemaOpts = {
  version?: "v4" | "v6"
  message?: string
}

export function optionalUuid(message?: ErrMessage) {
  return z.string().uuid(message).optional().or(emptyStringToUndefinedSchema)
}

export function optionalEmail(message?: ErrMessage) {
  return z.string().email(message).optional().or(emptyStringToUndefinedSchema)
}

export function optionalUrl(message?: ErrMessage) {
  return z.string().url(message).optional().or(emptyStringToUndefinedSchema)
}

export function optionalIp(options?: IpSchemaOpts) {
  return z.string().ip(options).optional().or(emptyStringToUndefinedSchema)
}

const macAddressRegex = /^([\dA-Fa-f]{2}[:-]){5}([\dA-Fa-f]{2})$|^([\dA-Fa-f]{4}\.){2}([\dA-Fa-f]{4})$|^[\dA-Fa-f]{12}$/

export function macAddress(message?: ErrMessage) {
  return z.string().regex(macAddressRegex, message)
}

export function optionalMacAddress(message?: ErrMessage) {
  return macAddress(message).optional().or(emptyStringToUndefinedSchema)
}

const limitedMacAddressRegex = /^([\dA-Fa-f]{2}[:-]){5}([\dA-Fa-f]{2})$/

export function limitedMacAddress(message?: ErrMessage) {
  return z.string().regex(limitedMacAddressRegex, message)
}

export function limitedOptionalMacAddress(message?: ErrMessage) {
  return limitedMacAddress(message).optional().or(emptyStringToUndefinedSchema)
}

export function optionalPort(message?: ErrMessage) {
  return z.number().int(message).min(0, message).max(65535, message).optional().or(emptyStringToUndefinedSchema)
}

export function optionalNumber() {
  return z.number().optional().or(emptyStringToUndefinedSchema)
}

export const booleanSchema = z
  .union([z.string(), z.number(), z.boolean()])
  // transform to boolean
  .transform((val) => {
    if (typeof val === "boolean") {
      return val
    }

    if (typeof val === "number") {
      if (val === 1) {
        return true
      }

      if (val === 0) {
        return false
      }
    }

    if (typeof val === "string") {
      if (val.toLowerCase() === "true") {
        return true
      }

      if (val.toLowerCase() === "false") {
        return false
      }
    }

    return z.NEVER
  })
  // check if it's a really boolean
  .pipe(z.boolean())

export const numberSchema = z
  .union([
    z.string({
      errorMap: () => ({ message: "Zadejte číslo" }),
    }),
    z.number(),
  ])
  .transform((val) => {
    if (typeof val === "number") {
      return val
    }

    if (typeof val === "string") {
      if (val.trim() === "") {
        return z.NEVER
      }
      const parsed = Number(val)
      if (!isNaN(parsed)) {
        return parsed
      }
    }

    return z.NEVER
  })
  .pipe(
    z.number({
      errorMap: () => ({ message: "Zadejte číslo" }),
    }),
  )

export const optionalNumberSchema = z
  .union([
    z.string({
      errorMap: () => ({ message: "Zadejte číslo" }),
    }),
    z.number(),
    z.undefined(),
    z.null(),
  ])
  .transform((val) => {
    if (typeof val === "number") {
      return val
    }

    if (typeof val === "string") {
      if (val.trim() === "") {
        return z.NEVER
      }
      const parsed = Number(val)
      if (!isNaN(parsed)) {
        return parsed
      }
    }

    if (isNil(val)) {
      return val
    }

    return z.NEVER
  })
  .pipe(
    z
      .number({
        errorMap: () => ({ message: "Zadejte číslo" }),
      })
      .nullish(),
  )

export function nullish<T extends z.ZodSchema>(schema: T, params?: z.RawCreateParams) {
  return z.union([z.null(), z.undefined(), schema], params)
}
