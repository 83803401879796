export async function refetchQueries(queries: { refetch: () => Promise<unknown> }[]) {
  return Promise.all(queries.map((query) => query.refetch()))
}

type Query = {
  isLoading: boolean
  isError: boolean
  isRefetching: boolean
  refetch: () => Promise<unknown>
}

export function resolveQueriesState(queries: Query[]) {
  return {
    isLoading: queries.some((query) => query.isLoading),
    isError: queries.some((query) => query.isError),
    // TODO: update in future
    isRefetching: queries.some((query) => query.isRefetching),
    refetch: () => refetchQueries(queries),
  }
}
