export function mapValueToEnum<TValue, TEnum extends { [key: string]: TValue }>(
  val: TValue,
  enumObj: TEnum,
): TEnum[keyof TEnum] {
  for (const value of Object.values(enumObj)) {
    if (value === val) {
      return value as TEnum[keyof TEnum]
    }
  }

  throw new Error(`Unknown value: ${val}`)
}

export function mapValueToEnumKey<TValue, TEnum extends { [key: string]: TValue }>(
  val: TValue,
  enumObj: TEnum,
): keyof TEnum {
  for (const key of Object.keys(enumObj)) {
    if (enumObj[key] === val) {
      return key
    }
  }

  throw new Error(`Unknown value: ${val}`)
}

export function isEnumValue<TValue, TEnum extends { [key: string]: TValue }>(
  val: TValue,
  enumObj: TEnum,
): val is TEnum[keyof TEnum] {
  try {
    mapValueToEnum(val, enumObj)
    return true
  } catch {
    return false
  }
}
