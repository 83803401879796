// @ts-check
import { optionalUrl, safeParseInt } from "@deltagreen/utils"
import { booleanSchema } from "@deltagreen/utils"
import { createEnv } from "@t3-oss/env-nextjs"
import { z } from "zod"

const portSchema = z
  .union([z.string(), z.number()])
  // transform to number
  .transform((val) => {
    if (typeof val === "number") {
      return val
    }

    return safeParseInt(val)
  })
  // check if it's a really number
  .pipe(z.number().min(1).max(65535))

const urlArray = z
  .string()
  .transform((s) => s.split(","))
  .pipe(z.array(optionalUrl()))

const sentry = {
  ENVIRONMENT: z.string(),
  SENTRY_DSN: z
    .string()
    .url()
    .default("https://f4145584835d4df38130180e385f7f0d@o31760.ingest.sentry.io/4504048510304256"),
  SENTRY_RELEASE: z.string(),
  SENTRY_DISABLE: booleanSchema,
}

const env = createEnv({
  server: {
    MAINTENANCE_MODE: booleanSchema.default(false),
    PORT: portSchema,
    NODE_ENV: z.enum(["development", "test", "production"]).default("development"),
    ENVIRONMENT: z.string(),
    LOG_LEVEL: z.enum(["fatal", "error", "warn", "info", "debug", "trace"]).default("info"),
    GTM_ID: z.string().optional().default("GTM-5TPT34XV"),
    DISABLE_CACHE: booleanSchema,
    AUTH_SECRET: z.string().min(32),
    AUTH_SECRET_REMOTE: z.string().min(32),
    ENCRYPTION_SECRET: z.string(),
    PROTEUS_URL: z.string().url(),
    DEVTOOLS_ENABLED: booleanSchema.default("false"),

    SENTRY_DSN: z
      .string()
      .url()
      .default("https://f4145584835d4df38130180e385f7f0d@o31760.ingest.sentry.io/4504048510304256"),
    SENTRY_RELEASE: z.string(),
    SENTRY_DISABLE: booleanSchema,

    DATABASE_URL: z.string().url(),
    DATABASE_REPLICA_URL: z.string().url(),
    REDIS_URL: z.string().url(),
    SOCKET_URL: z.string().url(),
    AMQP_URL: z.string().url(),

    GOOGLE_CLOUD_BASE64_CREDENTIALS: z.string(),
    GOOGLE_CLOUD_PROJECT_ID: z.string().default("nano-green-1"),
    GOOGLE_CLOUD_DATA_PROJECT_ID: z.string(),
    GOOGLE_CLOUD_BUCKET_NAME: z.string(),

    GOOGLE_API_KEY: z.string(),
    GOOGLE_API_URL: z.string().nullish(),

    NANO_MAILER_URL: z.string().url(),
    NANO_MAILER_API_KEY: z.string(),

    NANO_PORTAL_URL: z.string().url(),
    NANO_PORTAL_API_KEY: z.string(),

    SOLAX_API_URL: z.string().url(),

    VICTRON_ENERGY_URL: z.string().url(),
    VICTRON_ENERGY_USERNAME: z.string(),
    VICTRON_ENERGY_PASSWORD: z.string(),

    TESLA_PUBLIC_KEY_BASE64: z.string(),
    TESLA_CLIENT_ID: z.string(),
    TESLA_CLIENT_SECRET: z.string(),
    TESLA_VEHICLE_PROXY_URL: z.string().url(),
    TESLA_VEHICLE_PROXY_API_KEY: z.string(),

    HTTP_PROXIES: urlArray.default(""),

    WEBFLOW_API_URL: z.string().url(),
    WEBFLOW_API_TOKEN: z.string(),

    INFIGY_EMAIL: z.string(),

    MAPYCZ_API_KEY: z.string(),
    MAPYCZ_API_URL: z.string(),
  },
  client: {
    NEXT_PUBLIC_SENTRY_DSN: sentry.SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_RELEASE: sentry.SENTRY_RELEASE,
    NEXT_PUBLIC_SENTRY_DISABLE: sentry.SENTRY_DISABLE,
    NEXT_PUBLIC_ENVIRONMENT: sentry.ENVIRONMENT,
  },
  experimental__runtimeEnv: {
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_RELEASE: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
    NEXT_PUBLIC_SENTRY_DISABLE: process.env.NEXT_PUBLIC_SENTRY_DISABLE,
    NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
  },
})

export function isProd() {
  return env.ENVIRONMENT === "production"
}

export function isDev() {
  return env.ENVIRONMENT === "development"
}

export function isLocal() {
  return env.ENVIRONMENT === "local"
}

export default env
